import React, { FC } from "react";
import { IProps } from "./Login.types";
import { Button, Form, Input } from "antd";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { Link } from "react-router-dom";

const LoginView: FC<IProps> = (props) => {
    const {
        formRef, rules, initialValues, loading, setLoading, isSubmitting, handleChange, handleSubmit, handleSubmitFailed
    } = props;

    return (
        <Form
            className='form w-100'
            id='kt_login_signin_form'
            form={formRef}
            name="loginForm"
            layout="vertical"
            scrollToFirstError={true}
            initialValues={initialValues}
            onValuesChange={handleChange}
            onFinish={handleSubmit}
            onFinishFailed={handleSubmitFailed}
        >
            <div className='text-center mb-11'>
                <h1 className='text-dark fw-bolder mb-3'>Login</h1>
                <div className='text-gray-500 fw-semibold fs-6'>Equipments Monitoring System</div>
            </div>

            {/* <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>
                    Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
                    continue.
                </div>
            </div> */}

            <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                <Form.Item
                    name="username"
                    rules={rules.email}
                >
                    <Input
                        placeholder='Email'
                        autoComplete='off'
                        className={'form-control bg-transparent'}
                    />
                </Form.Item>
            </div>

            <div className='fv-row mb-3'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                <Form.Item
                    name="password"
                    rules={rules.password}
                >
                    <Input
                        placeholder='Password'
                        type='password'
                        autoComplete='off'
                        className={'form-control bg-transparent'}
                    />
                </Form.Item>
            </div>

            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                <div />

                {/* <Link to='/auth/forgot-password' className='link-primary'>
                    Forgot Password ?
                </Link> */}
            </div>

            <div className='d-grid mb-10'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                    disabled={isSubmitting}
                // disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>Login</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>

            {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
                Not a Member yet?{' '}
                <Link to='/auth/registration' className='link-primary'>
                    Sign up
                </Link>
            </div> */}
        </Form>
    );
}

export default React.memo(LoginView);