// src/components/Notification.tsx
import React, { useContext, useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../../../../app/context/auth/auth.context';
import { Console } from 'console';

interface NotificationEventData {
    message: string;
}

const Notification: React.FC = () => {
    const [messages, setMessages] = useState<string[]>([]);
    const { userName, userId, userEmail, organogramId, organizationId, organogramIds } = useContext(AuthContext);

    useEffect(() => {
        const pusherKey = process.env.REACT_APP_PUSHER_KEY!;
        const cluster = process.env.REACT_APP_PUSHER_CLUSTER!;

        // Initialize Pusher
        const pusher = new Pusher(pusherKey, {
            cluster: cluster,
            forceTLS: true,
        });

        // Subscribe to the user-specific 'notifications.{organogramId}' channel
        const channel = pusher.subscribe(`notifications.${userId}`);

        // Debugging: Log connection state
        pusher.connection.bind('state_change', (states: any) => {
            console.log('Pusher connection state changed:', states);
        });

        // Debugging: Log successful connection
        pusher.connection.bind('connected', () => {
            console.log('Pusher connected successfully');
        });

        // Bind to the 'NotificationEvent' event within the channel
        channel.bind('NotificationEvent', (data: NotificationEventData) => {
            console.log('Received event data:', data); // Debugging line
            toast.info(`New notification: ${data.message}`);
            setMessages((prevMessages) => [...prevMessages, data.message]);
        });

        // Cleanup function to unsubscribe from the channel
        return () => {
            channel.unbind_all();
            channel.unsubscribe();
        };
    }, []);

    return (
        <div>
            <ul>
                {/* {messages.map((msg, index) => (
                    <li key={index}>{msg}</li>
                ))} */}
            </ul>
            <ToastContainer />
        </div>
    );
};

export default Notification;
