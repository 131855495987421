/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Descriptions, Row, Col, Radio, Button, Form } from 'antd';
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
// import { DeviceApi } from "../../../../../api";
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  CustomTablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
  ChartsWidget6,
  ChartsWidget3,
  ChartsWidget1,
  ChartsWidget2,
  ChartsWidget4,
  ChartsWidget5,
  ChartsWidget7,
  ChartsWidget8,
  TablesWidget9
} from '../../../_metronic/partials/widgets'
import { Message } from '../../utils';
import { DeviceApi } from '../../api';
import { useCrudViewService } from '../../hooks/crud/useCrudViewService';
import { CustomTablesWidget9 } from '../../../_metronic/partials/widgets/tables/CustomTablesWidget9_1';
import DeviceListController from '../../../_metronic/partials/widgets/tables/List/DeviceList.controller';

const initialState = {
  modalTitle: 'Device Info',
  itemData: {},
  loading: false,
  fields: {},
  message: {
    network_error: 'A network error occurred. Please try again later.',
    create_success: 'Command send successfully.',
  }
}


const handleCommand = (commandValue: any, hardware_id: any, relay: any) => {
  handleCommandSubmit(commandValue, hardware_id, relay);
}

const handleCommandSubmit = (commandValue: any, hardware_id: any, relay: any): Promise<any> => {
  const payload = {
    command: commandValue,
    hardware_id: hardware_id,
    relay: relay
  };
  return new Promise((resolve, reject) => {
    // setIsSubmitting(true);
    DeviceApi.sendCommand(payload)
      .then((res: any) => {
        Message.success(initialState.message.create_success);
        // setIsSubmitting(false);
        resolve(res);
      })
      .catch((err: any) => {
        if (err?.status === 409) {
          // setErrors(err.data);
        }
        else if (err?.status === 412) {
          // setErrors(err.data);
        }
        else if (err?.status === 422) {
          Message.error(err.data)
        } else {
          Message.error(initialState.message.network_error);
        }
        // setIsSubmitting(false);
        reject(err);
      });
  });
}
const DashboardPage: FC<any> = props => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {/* begin::Col */}
      <div className='col-md-12 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget20
          className=' mb-5 mb-xl-10 h-md-50'
          // description='Active Equipment'
          // number='2'
          color='#F1416C'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />
        {/* <CardsWidget7
          className='h-md-50 mb-5 mb-xl-10'
          description='Equipment Management Engineers'
          icon={true}
          stats={20}
          labelColor='#F1416C'
          textColor='#F1416C'
        /> */}
          <CustomTablesWidget5 className='mb-5 mb-xl-10 h-md-50' />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
        <ListsWidget26 className='h-lg-50' />
      </div> */}
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-xxl-9'>
        {/* <EngageWidget10 className='h-md-100' /> */}
        {/* <CustomTablesWidget5 className='h-md-100' /> */}
        <ChartsWidget3 className='' />
        {/* <ChartsWidget8 className='h-md-100' /> */}
      </div>
      {/* end::Col */}
      <div className="col-xxl-12">
        <DeviceListController />
        {/* <CustomTablesWidget9 className='' /> */}
        {/* <CustomTablesWidget9 className='' /> */}
      </div>
    </div>
    {/* end::Row */}

  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
