/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { LeftSidebarMenu } from './LeftSidebar.menu';
import { Link } from 'react-router-dom'
import { usePermissionContext } from '../../../../../app/hooks/context/usePermissionContext'
import { ObjectUtils } from '../../../../../app/utils'

const SidebarMenuMain = () => {
	const intl = useIntl();
	const { isPermissionLoaded, hasPermission } = usePermissionContext();
	const [navigationList, setNavigationList] = useState<any>([]);

	useEffect(() => {
		let leftSideMenuList = ObjectUtils.objectClone(LeftSidebarMenu);
		if (isPermissionLoaded) {
			leftSideMenuList = leftSideMenuList.filter((item: any) => {
				if (item.children) {
					return item.children = (item.children.filter((childItem: any) => {
						if (childItem.subParent === true) {
							return childItem.subChildren = childItem.subChildren.filter((subChildItem: any) => hasPermission(subChildItem.permission));
						} else {
							return hasPermission(childItem.permission);
						}
					}));
				}
			});

			setNavigationList(leftSideMenuList);
		}
	}, [isPermissionLoaded]);

	return (
		<>
			{navigationList.map((item: any, index: number) => {
				return (
					<Fragment key={index}>
						{item.children.length > 0 && (
							<>
								{!item.hidden && (
									<div className='menu-item'>
										<div className='menu-content pt-8 pb-2'>
											<span className='menu-section text-muted text-uppercase fs-8 ls-1'>{item.title}</span>
										</div>
									</div>
								)}

								{item.children.map((childItem: any, childIndex: number) => {
									return (
										<Fragment key={childIndex}>
											{childItem.subChildren.length > 0 && (
												<SidebarMenuItemWithSub
													key={childIndex}
													to={childItem.link.to}
													title={childItem.title}
													fontIcon='bi-chat-left'
													icon={childItem.icon}
												>
													{childItem.subChildren.map((subChildItem: any, subChildIndex: number) => (
														<SidebarMenuItem key={subChildIndex} to={subChildItem.link.to} title={subChildItem.title} hasBullet={true} />
													))}
												</SidebarMenuItemWithSub>
											)}

											{childItem.subChildren.length == 0 && childItem.subParent === false && (
												<SidebarMenuItem key={childIndex} to={childItem.link.to} icon={childItem.icon} fontIcon='bi-app-indicator' title={childItem.title} />
											)}
										</Fragment>
									)
								})}
							</>
						)}
					</Fragment>
				)
			})}
		</>
	)
}

export { SidebarMenuMain }
