/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { DashboardApi } from '../../../../app/api';
import { Message } from '../../../../app/utils';
import { HttpService } from '../../../../app/services/http.services';
import { Spin } from 'antd';
// import { DashboardApi } from "../../../../../api";

type Props = {
	className: string
}

const CustomTablesWidget5: React.FC<Props> = ({ className }) => {
	const [errors, setErrors] = useState<any>({});
	const [itemData, setItemData] = useState<any>([]);
	const [category, setCategory] = useState<any>([]);
	const [count, setCount] = useState(0)
	const [loading, setLoading] = useState<boolean>(false);

	// setInterval(() => {
	// 	setCount(prev => prev + 1);
	// }, 15000)

	useEffect(() => {
		// loadData();
		fetchCategory();
	}, [])

	const fetchCategory = () => {
		setLoading(true);

		HttpService.get(`auth/api/v1/equipment/dashboard_category`)
			.then(response => {
				setCategory(response.data);
				setLoading(false);
			})
			.catch(error => {
				console.error('Error fetching user data:', error);
			});
	};

	// const loadData = (): Promise<any> => {
	// 	return new Promise((resolve, reject) => {
	// 		DashboardApi.list()
	// 			.then((res: any) => {
	// 				setItemData(res.data.results);
	// 				resolve(res);
	// 			})
	// 			.catch((err: any) => {
	// 				if (err?.status === 409) {
	// 					setErrors(err.data);
	// 				}
	// 				else if (err?.status === 412) {
	// 					setErrors(err.data);
	// 				}
	// 				else if (err?.status === 422) {
	// 					Message.error(err.data)
	// 				} else {
	// 					Message.error('A network error occurred. Please try again later.');
	// 				}
	// 				reject(err);
	// 				// console.log(err);
	// 			});
	// 	});
	// }
	const myStyle = {
		backgroundColor: '#2f8bf1cf',
	};

	return (
		<div className={`card ${className}`} style={{ overflowY: 'auto', backgroundColor: '#2f8bf1b5' }}  >

			{/* <div className='card-header border-0 pt-5'>
				<h3 className='card-title align-items-start flex-column'>
					<Spin size="small" />
					<span className='card-label fw-bold fs-3 mb-1 text-white'>Categories</span>
				</h3>
			</div> */}

			<div className='card-body py-3'>
				{/* {loading && <Spin size="small" />}
				{
					category?.map((item: any) => (
						<p className='text-start'>
							{item.name}
						</p>
					))
				} */}
			</div>
		</div>
	)
}

export { CustomTablesWidget5 }
