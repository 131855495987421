import { useState, useEffect } from "react";
import { DeviceApi } from "../../api";

export const useEquipmentList = () => {
    // USED STATES
    const [equipmentList, setEquipmentList] = useState<any>([]);
    const [activeEquipmentList, setActiveEquipmentList] = useState<any>([]);
    const [loadingEquipmentList, setLoadingEquipmentList] = useState<boolean>(false);

    useEffect(() => {
        loadEquipmentList();
    }, []);

    const loadEquipmentList = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            setLoadingEquipmentList(true);
            const payload = {
                $select: "id,name,status",
                $orderby: "sort_order asc",
            };
            DeviceApi.dropdown(payload)
                .then((res) => {
                    const list = res.data.results;
                    if (list.length > 0) {
                        setEquipmentList(list);
                        const activeEquipments = list.filter((item: any)=> item.status === 1);
                        setActiveEquipmentList(activeEquipments);
                    }
                    setLoadingEquipmentList(false);
                    resolve(res.data);
                })
                .catch((err) => {
                    setLoadingEquipmentList(false);
                    reject(err);
                });
        });
    };

    const getEquipmentById = (id: any) => {
        if (!equipmentList) {
            return;
        }
        return equipmentList.find((item: any) => item.id === Number(id));
    };

    const setEquipmentFormFieldValue = (formRef: any, key: any, value: any) => {
        if (equipmentList?.find((item: any) => item.id === Number(value))) {
            formRef.setFieldsValue({ [key]: value });
        } else {
            formRef.setFieldsValue({ [key]: null });
        }
    };

    return {
        loadingEquipmentList,
        equipmentList,
        activeEquipmentList,
        setEquipmentFormFieldValue,
        getEquipmentById
    };
};
